export const locale = {
    lang: 'en',
    data: {
        'TOOLBAR': {
            'GATE': 'Gate',
            'LENTRY': 'Last Entry',
            'DESK_ATTENDANT': 'Lobby Attendant',
        }
    }
};
