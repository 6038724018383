export const locale = {
    lang: 'es',
    data: {
        'TOOLBAR': {
            'GATE': 'Puerta',
            'LENTRY': 'Ultima Entrada',
            'DESK_ATTENDANT': 'Asistente de Lobby',
        }
    }
};
