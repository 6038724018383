import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AuthService } from '../main/auth/auth.service';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { rolesNavigation } from '../fuse-config/roles';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../main/owner/profile/profile.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
      private router: Router,
      private http: HttpClient,
      public authService: AuthService,
      public fuseConfigService: FuseConfigService,
      private _fuseNavigationService: FuseNavigationService,
      private translate: TranslateService,
      private profileService: ProfileService
    ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
        // console.log('Auth guard', this.authService.isAuthenticated);
        if ( (this.authService.isAuthenticated === false && localStorage.getItem('token') !== null) || 
        (this.authService.isAuthenticated === false && sessionStorage.getItem('token') !== null)) {
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
            const headers = new HttpHeaders().set('authorization', token);
            headers.append('Content-Type', 'application/json');
            return this.http.get(fuseConfig.url + '/profile/getProfile', {headers: headers, withCredentials: true}).map((data: any) => {
                this.authService.isAuthenticated = true;
                this.fuseConfigService.config = {
                    user: {
                        name: data.name,
                        lastname: data.lastname,
                        email: data.email,
                        photo: data.photo ? 'data:image/png;base64,' + data.photo : null,
                        id: data.user
                    },
                    colorTheme: data.darktheme ? 'theme-blue-gray-dark' : 'theme-default',
                    listDemo: data.listdemo
                };
                this.translate.setDefaultLang(data.language);
                this.translate.use(data.language);
                this.profileService.setLanguage(data.language);
                // Find each role to enable navigation
                this._fuseNavigationService.setNavigationRoles(data.roles);
                // Set global roles booleans
                this.fuseConfigService.config = {
                    isOwner: false,
                    isSecurity: false,
                    isSecuritySupervisor: false,
                    isBuildingAdmin: false,
                    isTowerAdmin: false,
                    isGoutruAdmin: false,
                    isDealer: false,
                    isDealerTech: false,
                };
                // console.log(this.fuseConfigService.config);
                const own = data.roles.findIndex((role) => {
                    return role === 'owner';
                });
                if (own >= 0) {

                    this.fuseConfigService.config = {
                        isOwner: true,
                    };
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: false
                    });
                    this.router.navigate(['/owner/dashboard']);
                }
        
                const sec = data.roles.findIndex((role) => {
                    return role === 'security' || role === 'security_assist';
                });
                if (sec >= 0) {
                    this.fuseConfigService.config = {
                        isSecurity: true,
                    };
                    sessionStorage.setItem('access_gate_type', data.access_gate_type)
                    if (data.access_gate_type === 1) {
                        this._fuseNavigationService.updateNavigationItem('desk_attendant', {
                            hidden: true
                        })
                        this._fuseNavigationService.updateNavigationItem('control', {
                            hidden: false
                        })
                        this.router.navigate(['/security/control']);
                    } 
                    if (data.access_gate_type === 2) {
                        this._fuseNavigationService.updateNavigationItem('desk_attendant', {
                            hidden: false
                        })
                        this._fuseNavigationService.updateNavigationItem('control', {
                            hidden: true
                        })
                        this.router.navigate(['/security/desk-attendant']);
                    } 
                    if (data.access_gate_type === 99) {
                        this._fuseNavigationService.updateNavigationItem('desk_attendant', {
                            hidden: false
                        })
                        this._fuseNavigationService.updateNavigationItem('control', {
                            hidden: false
                        })
                        this.router.navigate(['/security/control']);
                    }
                    if (data.access_gate_type === 0) {
                        this.router.navigate(['/security/today']);
                        this._fuseNavigationService.updateNavigationItem('desk_attendant', {
                            hidden: true
                        })
                        this._fuseNavigationService.updateNavigationItem('control', {
                            hidden: true
                        })
                    }
                    this.router.navigate([data.access_gate_type === 2 ? '/security/desk-attendant' : data.access_gate_type === 0 ? '/security/today' : '/security/control'])
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: true
                    });
                    if (data.roles.includes('security') && !data.roles.includes('security_assist')) {
                        this._fuseNavigationService.updateNavigationItem('security_report_access_mon', {
                            hidden: true
                        }); 
                        this._fuseNavigationService.updateNavigationItem('admbuild_monitor', {
                            hidden: true
                        }); 
                    }
                }

                const admb = data.roles.findIndex((role) => {
                    return role === 'adminbuilding';
                });
                if (admb >= 0) {
                    this.fuseConfigService.config = {
                        isBuildingAdmin: true,
                    };
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: false
                    });
                }

                const secs = data.roles.findIndex((role) => {
                    return role === 'security';
                });
                if (secs >= 0) {
                    this.fuseConfigService.config = {
                        isSecuritySupervisor: true,
                    };
                }

                const gtadm = data.roles.findIndex((role) => {
                    return role === 'goutruadmin';
                });
                if (gtadm >= 0) {
                    this.fuseConfigService.config = {
                        isGoutruAdmin: true,
                    };
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: false
                    });
                }
                const dealer = data.roles.findIndex((role) => {
                    return role === 'dealer';
                });
                if (dealer >= 0) {
                    this.fuseConfigService.config = {
                        isDealer: true,
                    };
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: false
                    });
                }

                const dealertech = data.roles.findIndex((role) => {
                    return role === 'dealertech';
                });
                if (dealertech >= 0) {
                    this.fuseConfigService.config = {
                        isDealerTech: true,
                    };
                    this._fuseNavigationService.updateNavigationItem('profile', {
                        hidden: true
                    });
                }

                return true;

                return true;
            }, (err) => {
                // console.log(err);
                this.fuseConfigService.config = {
                    layout: {
                        navbar   : {
                            hidden: true
                        },
                        toolbar  : {
                            hidden: true
                        },
                        footer   : {
                            hidden: true
                        },
                        sidepanel: {
                            hidden: true
                        }
                    }
                };
                this.router.navigate(['/auth/login']);
                return false;
            });
        } else if (this.authService.isAuthenticated === true) {
            return true;
        } else {
            // console.log('aca');
            this.fuseConfigService.config = {
                layout: {
                    navbar   : {
                        hidden: true
                    },
                    toolbar  : {
                        hidden: true
                    },
                    footer   : {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
            this.router.navigate(['/auth/login']);
            return false;
        }
  }
}